<template>
  <v-container fluid style="min-height: 2500px;">
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Document Types</h1>
        <v-btn v-if="!selectedType.id" @click="openCreateEditDialog('create')" :color="selectedType.id?'info':'success'" class="ml-2">Create Document Type</v-btn>
        <v-btn v-else @click="openCreateEditDialog('edit')" :color="selectedType.id?'info':'success'" class="ml-2">Update Document Type Info</v-btn>
        <confirmedActionButton class="ml-2" color="success" @cb="importFiles" :buttonText="'Import Files into DB'" :dialogText="'Are you sure you wish to proceed?'" />
        <v-btn v-if="selectedType.id" @click="updateDocumentType" color="info" class="ml-2">Update Document Type</v-btn>
        <confirmedActionButton class="ml-2" color="error" v-if="selectedType.id" @cb="deleteDocumentType" :buttonText="'Delete Document Type'" :dialogText="'Are you sure you wish to proceed?'" />
        <v-btn v-if="selectedType.id" @click="clearDocumentType" color="warning" class="ml-2">Clear Document Type</v-btn>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="initialLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="!initialLoader">
      <v-col cols=3>
        <h2>Selected: {{selectedType.name.toUpperCase() || '-' }}</h2>
        <!-- <p>{{documentTypes.data}}</p> -->
        <div v-for="document,index in documentTypes.data" :key="index">
          <v-card class="mb-2" outlined>
            <v-card-text class="d-flex flex-row justify-space-between align-center">
              <span class="d-flex flex-column">
                <h4>{{ document.name.toUpperCase() }}</h4>
                <span>{{ document.name }} | ID: {{ document.id }}</span>
              </span>
              <v-btn fab color="success" x-small @click="selectDocumentType(document.id)"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col style="margin: 0; padding: 0;">
        <div v-if="editorEnabled" id="pugEditor" ref="acePUG"></div>
        <div v-if="editorEnabled" id="cssEditor" ref="aceCSS"></div>
      </v-col>
    </v-row>
    <v-dialog v-model="documentDialog.isOpen" width="600px" persistent>
      <v-card>
        <v-card-title class="text-h5">Create/Edit Document Type</v-card-title>
        <v-card-text>
          <v-text-field label="name" v-model="documentDialog.name" />
          <v-text-field label="width" v-model="documentDialog.metadata.width" />
          <v-text-field label="height" v-model="documentDialog.metadata.height" />
          <v-text-field label="border" v-model="documentDialog.metadata.border" />
          <v-text-field label="bcTerm" v-model="documentDialog.metadata.bcTerm" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" v-if="this.createMode" :disabled="!documentDialog.name" :loading="documentDialog.loader" @click="createDocumentType">Create</v-btn>
          <v-btn color="success" v-else :disabled="!documentDialog.name" :loading="documentDialog.loader" @click="updateDocumentType">Update</v-btn>
          <v-btn color="primary" text @click="closeCreateEditDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <span v-html="printPreviewDialog.data.job.htmlToPrint"></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped>
  #pugEditor {
    /*border: 1px solid green;*/
    /*margin-top: 100px;*/
    margin-bottom: 100px;
    /*position: absolute;*/
    /*margin-left: 400px;*/
    z-index:999;
    width: 1000px;
    height: 800px;
  }

  #cssEditor {
    /*border: 1px solid blue;*/
    /*margin-top: 1000px;*/
    margin-bottom: 100px;
    /*position: absolute;*/
    /*margin-left: 400px;*/
    z-index:999;
    width: 1000px;
    height: 400px;
  }
</style>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios';
import ace from 'ace-code'
import confirmedActionButton from './../../components/confirmedActionButton.vue';
export default {
  components: {
    confirmedActionButton
  },
  name: 'Home',
  data () {
    return {
      initialLoader: true,
      editorEnabled: true,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      editMode: false,
      createMode: false,
      selectedType: {
        id: '',
        name: "",
        metadata: {
          height: '',
          width: '',
          border: '',
          bcTerm: '',
        }
      },
      documentTypes: {
        data: null,
      },
      printPreviewDialog: {
        isOpen: false,
        data: '',
      },
      cssEditor: {},
      pugEditor: {},
      documentDialog: {
        loader: false,
        isOpen: false,
        name: "",
        metadata: {
          height: '',
          width: '',
          border: '',
          bcTerm: '',
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint'])
  },
  async mounted(){
    try { 
      let res = await axios.get(`${this.getEndpoint}/api/documentTypes/`)
      if(res.data.error) throw res.data.error
      this.documentTypes.data = res.data.data
      this.initialLoader = false
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    openCSSEditor(css){
      this.editorEnabled = true
      this.$forceUpdate()
      this.cssEditor = ace.edit(this.$refs.aceCSS, {
        mode: 'ace/mode/css'
      });
      this.cssEditor.setOptions({
        fontSize: '16px',
        enableEmmet: true
      })
      // this.cssEditor.setOption("enableEmmet", true)
      this.cssEditor.setTheme("ace/theme/monokai");
      this.cssEditor.setValue(css)
    },
    openPUGEditor(pug){
      this.editorEnabled = true
      this.$forceUpdate()
      this.pugEditor = ace.edit(this.$refs.acePUG, {
        mode: 'ace/mode/jade',
      });
      // this.pugEditor.setOption("enableEmmet", true)
      this.pugEditor.setOptions({
        fontSize: '16px',
        enableEmmet: true
      })
      this.pugEditor.setTheme("ace/theme/monokai");
      this.pugEditor.setValue(pug)
    },
    closeCSSEditor(){
      this.editorEnabled = false
      this.cssEditor = {}
    },
    closePUGEditor(){
      this.editorEnabled = false
      this.pugEditor = {}
    },
    async selectDocumentType(id){
      try {
        this.selectedType.id = id
        console.log(this.selectedType)
        let res = await axios.get(`${this.getEndpoint}/api/documentTypes/${this.selectedType.id}`)
        if(res.data.error) throw res.data.error
        this.selectedType = res.data.data
        this.openPUGEditor(this.selectedType.metadata.pug)
        this.openCSSEditor(this.selectedType.metadata.css)
      } catch (error) {
       console.log(error) 
      }
    },
    async importFiles(){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/documentTypes/import`)
        if(res.data.error) throw res.data.error
      } catch (error) {
       console.log(error) 
      }
      
    },
    async updateDocumentType(){
      try {
        this.documentDialog.loader = true
        
        let obj = {...this.selectedType}
        
        if(this.cssEditor.getValue())
          obj.css = this.cssEditor.getValue()
        if(this.pugEditor.getValue())
          obj.pug = this.pugEditor.getValue()

        let res = await axios.put(`${this.getEndpoint}/api/documentTypes/${this.selectedType.id}`, obj)
        if(res.data.error) throw res.data.error
        this.snack("Updated")
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.documentDialog.loader = false
      }
    },
    async deleteDocumentType(){
      try {
        let res = await axios.delete(`${this.getEndpoint}/api/documentTypes/${this.selectedType.id}`)
        if(res.data.error) throw res.data.error

        this.selectedType = {
          name: "",
          metadata: {
            height: '',
            width: '',
            border: '',
            bcTerm: '',
          }
        }
        this.selectedType.id = ''
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    openCreateEditDialog(mode){
      this.editorEnabled = false
      if(mode=="create"){
        this.createMode = true
        this.documentDialog.isOpen = true
      }
      else{ 
        this.createMode = false
        this.documentDialog = this.selectedType
        this.documentDialog.isOpen = true
      }
    },
    closeCreateEditDialog(){
      this.createMode = false
      this.editorEnabled = true
      this.documentDialog = {
        isOpen: false,
        loader: false,
        name: "",
        metadata: {
          height: '',
          width: '',
          border: '',
          bcTerm: '',
        }
      }
    },
    async createDocumentType(){
      try {
        if(this.documentDialog.name){
          let obj = {name: this.documentDialog.name, metadata: this.documentDialog.metadata}
          obj.metadata.css = 'body'
          obj.metadata.pug = 'body:{}'

          let res = await axios.post(`${this.getEndpoint}/api/documentTypes/`, obj)
          if(res.data.error) throw res.data.error
          this.selectedType.id = ''
          this.closeCreateEditDialog()
        }
      }catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async printPreview(){
      try {
        let x = await axios.post(`${this.getEndpoint}/api/print/previewInvoice/${this.$route.params.id}`)
        if(x.data.error) throw x.data.error
        this.printPreviewDialog.data = x.data.data
        this.printPreviewDialog.isOpen = true
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
    },
    closePrintPreviewDialog(){
        this.printPreviewDialog.data = ''
        this.printPreviewDialog.isOpen = false
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    clearDocumentType(){
      this.closeCSSEditor()
      this.closePUGEditor()
      this.editorEnabled = true
      this.selectedType = {
        id: '',
        name: "",
        metadata: {
          height: '',
          width: '',
          border: '',
          bcTerm: '',
        }
      }
    }
  }
}
</script>
